<template>
  <v-row justify="end" align="end">
    <v-menu
    bottom
    origin="center center"
    transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          :id="idAcaoMassiva"
          @click="carregarVariaveis()"
          icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
      </template>
      <v-list v-if="this.status !== ''">
        <v-list-item v-if="mostrarAcompanhamento" @click="abrirAcompanhamento">
          <v-list-item-action>
              <v-icon>send</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $tc('label.acompanhamento') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'APROV')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>thumb_up</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.aprovar') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'EM_ANALISE')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>announcement</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.enviar_analise') }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="acaoModal(true,'REPROV')" v-if="exibeBotoes">
          <v-list-item-action>
              <v-icon>thumb_down</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ $t('label.reprovar') }}</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!exibeBotoes && !mostrarAcompanhamento">
          <v-list-item-action>
              <v-icon>info</v-icon>
          </v-list-item-action>
          <v-list-item-title>Fluxo não encontrado</v-list-item-title>
        </v-list-item>
      </v-list>
  </v-menu>
  <v-dialog v-model="showModal" max-width="800">
    <v-card>
      <v-card-title>
      <span class="bar" style="text-transform: uppercase">
        {{ aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : $tc('label.enviar_analise')}}
      </span>
      </v-card-title>
      <br>
      <v-card-subtitle>
        {{ $tc('title.confirm_acao_aprov_mass') }} {{ aprovar ? $tc('label.aprovar') : reprovar ? $tc('label.reprovar') : $tc('label.enviar_analise')}} {{ $tc('title.confirm_aprov_mass_apura_selec') }}
      </v-card-subtitle>
      <v-card-subtitle>
        {{ $tc('title.confirm_aprov_mass_irrev') }}
      </v-card-subtitle>
      <v-card-text>
        <v-textarea
          v-if="!aprovar"
          id="justificativa"
          name="justificativa"
          :label="`${$tc('label.justificativa', 1)}`"
          v-model="justificativa"
          auto-grow
          autofocus
          :rules="[rules.required]"
          rows="1"
        ></v-textarea>
        <v-textarea
          v-else
          id="justificativa"
          name="justificativa"
          :label="`${$tc('label.justificativa', 1)}`"
          v-model="justificativa"
          auto-grow
          autofocus
          rows="1"
        ></v-textarea>
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="cabecalho"
          :items="apuracoes">
          <template v-slot:item.id_acao="{ item }">
            {{ item.idAcao }}
          </template>
          <template v-slot:item.id_apuracao="{ item }">
            {{ item.idApuracao }}
          </template>
          <template v-slot:item.tipo_acao="{ item }">
            {{ item.tipoAcao }}
          </template>
          <template v-slot:item.tipo_verba="{ item }">
            {{ item.tipoVerba }}
          </template>
          <template v-slot:item.valor="{ item }">
            {{ getMoney(item.vlrConfApuracao) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="acaoModal(false,null)">{{ $t('label.nao') }}</v-btn>
        <v-btn color="primary" v-if="aprovar"   @click="aprovarApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="emAnalise && justificativa" @click="enviarAnaliseApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
        <v-btn color="primary" v-if="reprovar && justificativa"  @click="reprovarApuracao(justificativa)">{{ $t('label.sim') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <acompanhamento
      ref="modalAcompanhamento"
      :fluxo="statusPassos"/>
</v-row>
</template>

<script>
import { getMoney } from '@/produto/common/functions/helpers';
import { listarApuracoesSuzano } from '@/common/resources/apuracao-suzano';
import Acompanhamento from '../../shared-components/workflow/Acompanhamento';

export default {
  name: 'ApuracaoAcaoMassivaFluxo',
  components: {
    Acompanhamento,
  },
  props: {
    status: '',
    idAcaoMassiva: null,
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    exibeBotoes() {
      return this.isStatusAguardandoAprovacao && this.aprovadorFluxo && this.apuracoes.length;
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && !this.isStatusAguardandoApuracao
        && !this.isStatusApuracaoPrevia && !this.isStatusCancelado
        && !this.isStatusVencido && this.apuracoes.length;
    },
    isStatusAguardandoAprovacao() {
      return this.status === 'AGUARDANDO_APROVACAO';
    },
    isStatusAguardandoApuracao() {
      return this.status === 'AGUARDANDO_APURACAO';
    },
    isStatusApuracaoPrevia() {
      return this.status === 'APURACAO_PREVIA';
    },
    isStatusPendente() {
      return this.status === 'PENDENTE';
    },
    isStatusVencido() {
      return this.status === 'VENCIDO';
    },
    isStatusEmAnalise() {
      return this.status === 'EM_ANALISE';
    },
    isStatusCancelado() {
      return this.status === 'CANCELADO';
    },
  },
  data() {
    return {
      workflowApuracaAcaoResource: this.$api.workflowApuracaoAcao(this.$resource),
      aprovar: false,
      reprovar: false,
      emAnalise: false,
      showModal: false,
      justificativa: null,
      apuracoes: Array,
      buscando: false,
      aprovadorFluxo: false,
      statusPassos: {},
      cabecalho: [
        {
          text: this.$tc('label.cod_acao', 1), align: 'left', value: 'id_acao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.cod_apuracao', 1), align: 'left', value: 'id_apuracao', sortable: true, width: '10%',
        },
        {
          text: this.$tc('label.tipo_acao', 1), align: 'left', value: 'tipo_acao', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.tipo_de_verba', 1), align: 'left', value: 'tipo_verba', sortable: true, width: '15%',
        },
        {
          text: this.$tc('label.valor_pagamento_calculado', 1), align: 'left', value: 'valor_original', sortable: true, width: '10%',
        },
      ],
      pagination: {
        itemsPerPage: 10,
      },
      totalPage: 0,
      lista: [],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    getMoney,
    acaoModal(boolean, tipo) {
      this.showModal = boolean;
      this.limpaVariaveis();
      switch (tipo) {
        case 'APROV':
          this.aprovar = true;
          break;
        case 'EM_ANALISE':
          this.emAnalise = true;
          break;
        case 'REPROV':
          this.reprovar = true;
          break;
        default:
          return 'N/A';
      }
      return this.showModal;
    },
    carregarVariaveis() {
      this.buscar();
    },
    limpaVariaveis() {
      this.aprovar = false;
      this.emAnalise = false;
      this.reprovar = false;
      this.justificativa = null;
    },
    aprovarApuracao(justificativa) {
      Promise.all(this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaAcaoResource.aprovarPasso({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_aprov_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    reprovarApuracao(justificativa) {
      Promise.all(this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaAcaoResource.reprovar({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_reprov_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    enviarAnaliseApuracao(justificativa) {
      Promise.all(this.apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').map((c) => {
        const idApuracao = c.id;
        return this.workflowApuracaAcaoResource.enviarAnalise({ idApuracao },
          { observacao: justificativa });
      })).then(() => {
        this.showModal = false;
        this.$toast(this.$t('message.apuracao_em_analise_mass_sucesso'));
        this.$emit('AprovacaoMassivaApuracao_sucesso');
        this.$emit('LimpaSelecionados_sucesso');
      })
        .catch((err) => {
          this.showModal = false;
          this.$error(this, err);
        });
    },
    buscar() {
      this.consultar(listarApuracoesSuzano, (response) => {
        this.apuracoes = this.verificarUsuarioAprovador(response.data.resposta);
        setTimeout(this.finalizandoBusca);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        cod_acao_massiva: this.idAcaoMassiva,
        tamanhoPagina: 100,
        numeroPagina: 0,
      };

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    verificarUsuarioAprovador(apuracoes) {
      apuracoes.filter((el) => el.status === 'AGUARDANDO_APROVACAO').forEach((c) => {
        const idApuracao = c.id;
        this.workflowApuracaAcaoResource.aprovador({ idApuracao })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
          }).catch((err) => {
            this.$error(this, err);
          });
      });
      return apuracoes;
    },
    abrirAcompanhamento() {
      const { idApuracao } = this.apuracoes[0];
      this.workflowApuracaAcaoResource
        .existeFluxoCadastrado({ idApuracao })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.pagamento_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idApuracao);
        });
    },
    exibeModalAcompanhamento(idApuracao) {
      this.workflowApuracaAcaoResource.statusPasso({ idApuracao }, { idApuracao })
        .then((res) => {
          if (res.data.passos
            && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
  },
};
</script>
